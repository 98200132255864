import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { useChangeCampaignStatusMutation } from 'store/redux/states/ad_campaign/ad_campaign.api';

const statuses = {
  active: true,
  disabled: false,
};

const CampaignStatusSwitch = (props) => {
  const [changeStatusQuery, { isLoading }] = useChangeCampaignStatusMutation();
  const { active, status, campaignId, ...rest } = props;

  const checked = Boolean(active || statuses[status]);

  const onChange = (active) => {
    changeStatusQuery({
      campaign_id: campaignId,
      status: active ? 'active' : 'paused',
    }).unwrap();
  };

  return (
    <Switch
      size='small'
      checked={checked}
      onChange={onChange}
      isLoading={isLoading}
      disabled={isLoading}
      {...rest}
    />
  );
};

CampaignStatusSwitch.propTypes = {
  status: PropTypes.object.isRequired,
  campaignId: PropTypes.number.isRequired,
  active: PropTypes.bool,
};

export default CampaignStatusSwitch;
