import CampaignStatusSwitch from 'modules/campaigns/components/CampaignStatusSwitch';
import {IconTypeFormatter } from 'components/tables/NinjaTable/formatters';

import React from 'react';

const CampaignStatusTableFormatter = (props) => {
  /** @todo refactor when account is passed */
  const status = props.row?.campaign?.status ?? props.row?.status;
  const id = props.row?.campaign?.id ?? props.row?.id;

  return (
    <div className='flex items-center justify-between h-full gap-2'>
      {props.row?.campaign?.type && <IconTypeFormatter row={props?.row?.campaign} />}
      <CampaignStatusSwitch campaignId={id} status={status} />
    </div>
  );
};

export default CampaignStatusTableFormatter;
