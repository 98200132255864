import React from 'react';
import { useModal, useSharedData } from 'store/redux/states/ui/ui.hooks';
import Ninja from '@ninja';
import FormatterButton from 'components/table-formatters/components/FormatterButton';
import { mdiInformation, mdiContentCopy, mdiLink } from '@mdi/js';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import { NameFormatter, NetworkFormatter, StatusFormatter } from 'components/tables/NinjaTable/formatters';

const AdsetFormatter = ({ row, showNetwork = true }) => {
  const [, setUrlDetailsId] = useSharedData('url_details_id');
  const [modalData, setModal] = useModal('adset_copy');

  if (!row || !row.adset) {
    return 'No Adset';
  }

  const { adset, url } = row;

  const handleInfoButtonClick = () => {
    console.log('button clicked', adset);
    setUrlDetailsId(adset.id);
  };

  const duplicateAdset = () => {
    setModal({
      adset_id: adset.id,
    });
  };

  const handleUrl = () => {
    if (url?.url) {
      window.open(url.url, '_blank', 'noopener,noreferrer');
    }
  };


  const icons = [
    { icon: mdiLink, onClick: handleUrl, tooltip: 'Url', show: url ? true : false },
    // {
    //   icon: mdiContentCopy,
    //   onClick: duplicateAdset,
    //   show: adset.network === 'facebook',
    //   tooltip: 'Duplicate Adset',
    // },
    // { icon: mdiInformation, onClick: handleInfoButtonClick, tooltip: 'Details', show: adset.id },
  ];

  return (
    <FormatterButton className='icons-hover-trigger'>
      <div className='flex gap-2 items-center w-full relative'>
        {showNetwork && <NetworkFormatter row={adset} />}
        <StatusFormatter row={adset} />
        <NameFormatter name={adset.name} />
        <TableIcons color={Ninja.colors.light} icons={icons} />
      </div>
    </FormatterButton>
  );
};

export default AdsetFormatter;