import P from 'router/paths';

export const appTypes = {
  REVENUE: 'revenue',
  ECOMMERCE: 'ecommerce',
  MARKETING: 'marketing',
  MODULE: 'module',
  EMAIL: 'email',
};

export const apps = {
  meta: {
    app: 'meta',
    name: 'Meta Ads',
    type: [appTypes.MARKETING],
    description: 'Connect Meta Ads Account',
    image: '/icons/networks/meta.svg',
    tags: ['meta', 'account', 'ads', 'facebook', 'cost'],
  },
  google: {
    app: 'google-ads',
    name: 'Google Ads',
    type: [appTypes.MARKETING],
    description: 'Connect Google Ads Account',
    image: '/icons/networks/google.svg',
    tags: ['google', 'meta', 'account', 'ads', 'facebook', 'cost'],
  },
  woocommerce: {
    app: 'woocommerce',
    name: 'WooCommerce',
    type: [appTypes.REVENUE, appTypes.ECOMMERCE],
    description: 'Integrate and track your sales with MyDataNinja',
    image: '/images/woocommerce-integration.svg',
    tags: ['Woocommerce', 'wordpress', 'ecommerce', 'sales', 'products', 'shop'],
  },
  shopify: {
    app: 'shopify',
    type: [appTypes.REVENUE, appTypes.ECOMMERCE],
    name: 'Shopify',
    description: 'Connect Shopify',
    image: '/images/shopify-integration.png',
    tags: ['Woocommerce', 'shopify', 'ecommerce', 'sales', 'products', 'shop'],
  },
  mailerlite: {
    app: 'mailerlite',
    type: [appTypes.EMAIL],
    name: 'MailerLite',
    description: 'Connect MailerLite',
    image: '/images/mailerlite-integration.svg',
    tags: ['Mail', 'marketing', 'mailerlite', 'flow', 'email', 'shop'],
  },

  postbakcs: {
    app: 'postbaks',
    type: [appTypes.MODULE, appTypes.REVENUE],
    name: 'Postbacks',
    description: 'Postbacks',
    image: '/images/postbacks.webp',
    tags: ['Tracking', 'postback', 'webhook'],
    url: P.POSTBACKS.INDEX,
  },
  // cscart: {
  //   app: 'cscart',
  //   type: [appTypes.REVENUE, appTypes.ECOMMERCE],
  //   name: 'Cs Cart',
  //   description: 'Connect cs cart Store',
  //   image: '/images/cscart-integration.png',
  //   tags: ['Woocommerce', 'shopify', 'ecommerce', 'sales', 'products', 'shop', 'cart', 'cscart'],
  // },
  tiktok: {
    app: 'tiktok',
    name: 'TikTok',
    type: [appTypes.MARKETING],
    description: 'Connect TikTok Ad Account',
    image: '/images/tiktok.png',
    tags: ['Tiktok', 'account', 'ads', 'facebook', 'cost'],
  },
  // hubspot: {
  //   app: 'hubspot',
  //   name: 'HubSpot',
  //   type: [appTypes.REVENUE, appTypes.ECOMMERCE],
  //   description: 'Connect HubSpot Ad Account',
  //   image: '/icons/networks/hubspot.svg',
  //   tags: ['HubSpot', 'account', 'ads', 'facebook', 'cost'],
  // },
  bing: {
    app: 'bing-ads',
    name: 'Bing Ads',
    type: [appTypes.MARKETING],
    description: 'Connect Bing Ads Account',
    image: '/icons/networks/bing.svg',
    tags: ['bing', 'account', 'ads', 'facebook', 'cost'],
  },
  custom_urls: {
    app: 'custom_urls',
    type: [appTypes.MODULE, appTypes.MARKETING],
    name: 'Custom Urls',
    description: 'Custom Urls',
    image: '/images/custom-urls.png',
    tags: ['Tracking', 'short url', 'shortener', 'custom url'],
    url: P.URL.INDEX,
  },
};

export const getAppsByType = (type) => {
  return Object.entries(apps).reduce((acc, [key, app]) => {
    if (app.type.includes(type)) {
      acc[key] = app;
    }
    return acc;
  }, {});
};

export function getApp(app) {
  return apps[app];
}
