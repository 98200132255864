import { mdiCloseCircle } from '@mdi/js';
import React, { useMemo } from 'react';
import Ninja from '@ninja';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';

const TrackingStateFormatter = ({ isTracked }) => {
  const trackingIcon = useMemo(() => {
    if (!isTracked) {
      return {
        color: Ninja.colors.red,
        icon: mdiCloseCircle,
        tooltip: 'Tracking is not set up',
      };
    }
    return null;
  }, [isTracked]);

  if (!trackingIcon) {
    return null;
  }

  if (isTracked === undefined) {
    return null;
  }

  return (
    <TableIcons
      triggerHoverOnCell
      icons={[
        {
          icon: trackingIcon.icon,
          color: trackingIcon.color,
          tooltip: trackingIcon.tooltip,
          onClick: trackingIcon.onClick,
          size: '1rem',
        },
      ]}
    />
  );
};

export default TrackingStateFormatter;
