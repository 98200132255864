import React, { useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import CampaignDetailsPopover from 'components/Popovers/CampaignDetailsPopover';
import { mdiContentCopy, mdiFilterVariant } from '@mdi/js';
import { Flex } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import { useModal } from 'store/redux/states/ui/ui.hooks';
import { useHistory } from 'react-router-dom';
import P from 'router/paths';
import FormatterButton from 'components/table-formatters/components/FormatterButton';
import {
  TrackingStateFormatter,
  StatusFormatter,
  NameFormatter,
  NetworkFormatter
} from 'components/tables/NinjaTable/formatters';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const CampaignFormatter = ({ row, showNetwork = true }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [, setModal] = useModal('campaign_copy');
  const { setUrlParams } = useQueryParams();
  const history = useHistory();

  if (!row || !row.campaign) {
    return 'No Campaign';
  }

  const { campaign } = row;

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => {
    history.push(P.REPORTING.INDEX);
    setUrlParams({ group_by: 'adset', adset_ids: campaign.id });
  };

  const duplicateCampaign = () => {
    setModal({ campaign_id: campaign.id });
  };


  const icons = [
    // {
    //   icon: mdiContentCopy,
    //   onClick: duplicateCampaign,
    //   show: campaign.network === 'facebook',
    //   tooltip: 'Duplicate Campaign',
    // },
    { icon: mdiFilterVariant, onClick: handleChange, tooltip: 'Reports' },
  ];

  return (
    <FormatterButton className='icons-hover-trigger'>
      <div className='flex gap-2 items-center w-full relative'>
        <div className='flex items-center gap-1'>
          {showNetwork && <NetworkFormatter row={campaign} />}
          <TrackingStateFormatter isTracked={campaign.is_tracked} />
          <StatusFormatter row={campaign} />
        </div>
        <NameFormatter name={campaign.name} />
        <Flex>
          <TableIcons color={Ninja.colors.light} icons={icons} />
          {/* <InfoIcon
            className='text-color-light flex text-center w-full cursor-pointer hover:text-color-dark'
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup='true'
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          /> */}
        </Flex>
        <CampaignDetailsPopover
          id={campaign.id}
          open={open}
          onClose={handlePopoverClose}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      </div>
    </FormatterButton>
  );
};

export default CampaignFormatter;
